import React from 'react';
import { Routes as RoutesWrapper, Route } from 'react-router-dom';

import Home from './pages/Home';
import Terms from './pages/Terms';
import Policy from './pages/Policy';

export default function Routes() {
    return (
        <RoutesWrapper>
            <Route path="/" exact element={<Home />} />
            <Route path="/termos-de-uso" exact element={<Terms />} />
            <Route path="/politicas-de-privacidade" exact element={<Policy />} />
        </RoutesWrapper>
    );
}
