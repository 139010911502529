import React from 'react';
import DocumentWrapper from '../../components/DocumentWrapper';

const Terms = () => (
  <DocumentWrapper
    title="Termos de uso"
    source="https://drive.google.com/file/d/19ZC3-AKt8JnpdcIwGwhfHEHWW1vdC1z2/preview"
  />
);

export default Terms;
