import styled from 'styled-components';

export const Container = styled.div`
    margin: 24px;
    border-radius: 8px;

    background-color: ${({ theme }) => theme.colors.primary.main};

    header {
        display: flex;
        border-radius: 8px 8px 0px 0px;
        background-color: ${({ theme }) => theme.colors.primary.dark};
        height: 124px;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    img {
        width: 100%;
        height: 100%;
    }

    @media (min-width: 1248px) {
       img {
        width: 500px;
        height: 460px;
       }
    }
`;

export const Title = styled.p`
    color: ${({ theme }) => theme.colors.text.secundary};
`;
