import React from 'react';
import { Link } from 'react-router-dom';
import { ContactContainer, Container, LinkContainer } from './styles';

const Footer = () => (
    <Container>
      <ContactContainer>
        <small>luizgallasdev@gmail.com</small>
        <small>© 2022 Luiz Gallas</small>
      </ContactContainer>
      <LinkContainer>
        <Link to="/termos-de-uso">
          Termos de uso
        </Link>
        <Link to="/politicas-de-privacidade">
          Políticas de privacidade
        </Link>
      </LinkContainer>
    </Container>
  );

export default Footer;
