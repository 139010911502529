import React from 'react';

export interface iDocumentWrapperProps {
  source: string;
  title: string;
}

const DocumentWrapper: React.FunctionComponent<iDocumentWrapperProps> = ({
  source,
  title,
}) => (
  <iframe
    title={title}
    src={source}
    width="100%"
    height={window.innerHeight}
  />
);

export default DocumentWrapper;
