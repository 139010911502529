import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary.dark};

    height: 100px;
    width: 100%;
    padding: 0px 24px;

    a, small {
        font-size: 20px;
        color: rgba(255, 255, 255, .5);
    }


    @media (max-width: 1024px) {
        flex-direction: column;
        height: 200px;

        justify-content: space-evenly;
    }
`;

export const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    a {
        cursor: pointer;
    }

    a + a {
        margin-left: 40px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        
        a + a {
            margin-left: 0px;
        }
    }
`;
