import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background:linear-gradient(rgba(0, 0, 0), transparent);

    height: 126px;
    width: 100%;
`;
