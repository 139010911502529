import { createGlobalStyle } from 'styled-components';
import backgroundImage from '../images/BackgroundImage.svg';

export interface DefaultTheme {
    colors: {
        background: string,

        primary: {
        dark: string,
        main: string,
        },

        button: {
        primary: string
        },

        text: {
        primary: string
        },
    },
}

export default createGlobalStyle<{theme: DefaultTheme}>`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Signika', sans-serif;
    }

    :root {
        @media (max-width: 1024px) {
            p {
                font-size: 18px;
            }

            a, small {
                font-size: 16px;
            }

            h1 {
                font-size: 30px;
            }
        }
    }

    body {
        p {
            font-size: 20px;
            color: ${({ theme }) => theme.colors.text.primary}
        }

        background-image: url(${backgroundImage});

        font-size: 30px;
        color: ${({ theme }) => theme.colors.text.primary}
    }
    
    button {
        cursor: pointer;
    }
`;
