import React from 'react';
import DocumentWrapper from '../../components/DocumentWrapper';

const Policy = () => (
  <DocumentWrapper
    title="Politica de privacidade"
    source="https://drive.google.com/file/d/1LHw9e5RKAMD3UgT3wtTWxT96d1nlUHXW/preview"
  />
);

export default Policy;
