import styled from 'styled-components';

export const Container = styled.div`
    display: 'flex';
    justify-content: center;
`;

export const BlockContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1248px) {
        flex-direction: row;
    }

    a + a {
        margin-left: 24px;
    }
`;

export const RowContainer = styled.div`
    display: grid;
`;

export const DataContainer = styled.div`
    display: flex;
    padding: 20px 0px;

    p {
        text-align: center;
        padding: 0px 10px;
    }
`;

export const DownloadContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary.main};
    padding: 24px 0px;
`;

export const DownloadData = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.primary};
`;
