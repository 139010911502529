import React, { ReactNode } from 'react';
import { Container, Title } from './styles';

export interface IDataBlockProps {
    title: string,
    children: ReactNode
}

const DataBlock: React.FunctionComponent<IDataBlockProps> = ({
  title,
  children,
}) => (
  <Container>
    <header>
      <Title>{title}</Title>
    </header>
    {children}
  </Container>
);

export default DataBlock;
