import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import IconImage from '../../assets/images/Icon.png';

export interface IHeaderProps {
    onPressDownload?: () => void
}

const Header: React.FunctionComponent<IHeaderProps> = () => (
    <Container>
      <Link to="/">
        <img src={IconImage} alt="Babylon, Organize suas rotinas" />
      </Link>
    </Container>
  );

export default Header;
