// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors: {
    background: '#000',

    primary: {
      dark: '#000',
      main: '#FFFF',
    },

    button: {
      primary: '#E2A526',
      dark: '#7B5B18',
    },

    text: {
      primary: '#000',
      secundary: '#FFFF',
    },
  },
};
