/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import DataBlock from '../../components/DataBlock';
import {
  Container, BlockContainer, DownloadContainer, DownloadData,
} from './styles';
import RegisterImage from '../../assets/images/RegisterImage.png';
import GoalsImage from '../../assets/images/GoalsImage.png';
import AndroidLogo from '../../assets/images/AndroidLogo.png';
import AppleLogo from '../../assets/images/AppleLogo.png';

function Home() {
  return (
    <Container>
      <BlockContainer>
        <DataBlock title="Registre e visualize seus ganhos e despesas">
          <img
            src={RegisterImage}
            alt="Listagem de registros"
          />
        </DataBlock>
        <DataBlock title="Crie metas e acompanhe sua evolução">
          <img
            src={GoalsImage}
            alt="Listagem de registros"
          />
        </DataBlock>
      </BlockContainer>
      <DownloadContainer>
        <DownloadData className="download-container">
          <h1>Faça já o download</h1>
          <BlockContainer>
            <a href="https://play.google.com/store/apps/details?id=com.gallas.babylon.app">
              <img height={130} src={AndroidLogo} alt="Logo da Google Play para download" />
            </a>
            <a href="https://apps.apple.com/pt/app/babylon/id1623003534">
              <img src={AppleLogo} alt="Logo da App Store para download" />
            </a>
          </BlockContainer>
        </DownloadData>
      </DownloadContainer>
    </Container>
  );
}

export default Home;
